import Grid from '@mui/material/Grid';
import SupersetDashboard from '../AiDashboard/SupersetDashboard';
import SensorHealth from './SensorHealth';

export default function Dashboard ({type='Home'}) {
    
    return( 
        <Grid container>
            <Grid item  md={12}>
                <div className='w3-padding-large'>
                    <h5 className="card-title">{type} Dashboard</h5>
                    <div>
                        <SupersetDashboard 
                            key={type}
                            dashboardType={type}
                            dashboardHeight="620px"
                        />
                    </div>
                    {type === 'Home' && (
                        <SensorHealth />
                    )}
                </div>
            </Grid>
        </Grid>   
    )
}




